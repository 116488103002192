import React from "react";
import { Link } from "gatsby";

import * as styles from "./ErrorPage.module.scss";

type ErrorPageProps = {
  id: string;
  errorCode: number;
  errorText: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ id, errorCode: code, errorText: text }) => {
  return (
    <div id={id ? id : "unknownError"} className={styles.error}>
      <h1 className={styles.error__code}>{code ? code : "500"}</h1>
      <p className={styles.error__text}>{text ? text : "Something went wrong..."}</p>
      <Link to="/" className={`${styles.error__btn} btn`}>
        Portfolio &rarr;
      </Link>
    </div>
  );
};

export default ErrorPage;
