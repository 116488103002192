import React from "react";
import ErrorPage from "../components/error/ErrorPage";
import SEO from "../components/Seo";

import "../assets/scss/main.scss";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <SEO subTitle="Page Not Found" titleTemplate="%s | Vladimir Piven Portfolio" />
      <ErrorPage id="notFound" errorCode={404} errorText="Oops! The page is missing..." />
    </React.Fragment>
  );
};

export default NotFoundPage;
